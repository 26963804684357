/* Keyframe animations */
@keyframes bg-animation {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
  
  .animate-bg {
    background-size: 200% 200%;
    animation: bg-animation 8s ease infinite;
  }
  
  .rotate-90 {
    transform: rotate(90deg);
  }
  