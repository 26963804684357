.Homecompcont{
  background: url(../Images\ Homepage/aboutimgback.jpg);
  background-size: cover;
}

.wrapper{
    /* height: 90vh; */
    background-image: linear-gradient(#ea5a32, #00a958);
    padding-top: 10vh;
}

.wrapcont{
    width: 80vw;
    margin-left: 10vw;
    overflow: hidden;
}

.hgfd {
  margin-top: 30px;
}

/* @media (min-width: 768px) {
  .hgfd {
    animation: slide-left 2.5s ease forwards;
  }
} 
@keyframes slide-left {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(80px);
    }
} */

.homimgcont img{
    margin-top: 20px;
    width: 400px;
    height: 360px; 
}

@keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translateX(-50px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slideInRight {
  from {
    transform: translateX(50px);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

.animate-fade-in-left {
  animation: fadeInLeft 1s ease-in-out;
}

.animate-slide-in-right {
  animation: slideInRight 1s ease-in-out;
}

.w-128a{
    width: 400px;
}
/* 
.itemwrap{
  opacity: 0;
  animation: slideshow 8s infinite; /* Adjust the duration and other animation properties as needed 
}

.itemwrap:nth-child(1) {
  animation-delay: 0s;
}

.itemwrap:nth-child(2) {
  animation-delay: 6s;
}

.itemwrap:nth-child(3) {
  animation-delay: 12s;
}
.itemwrap:nth-child(4) {
  animation-delay: 18s;
}

.itemwrap:nth-child(5) {
  animation-delay: 24s;
}

.itemwrap:nth-child(6) {
  animation-delay: 30s;
}

.itemwrap:nth-child(7) {
  animation-delay: 36s;
}

@keyframes slideshow {
  0% {
    opacity: 1;
  }
  90%{
    opacity: 0;
  }
} */
.imgl{
  position: absolute;
  border: 4px solid grey;
  border-radius: 2em;
  margin-top: 160px;
  height: 350px;
  width: 350px;
}

.wrapbeltext{
  background: linear-gradient(-45deg, #ddd6d6, #444461);
  padding-top: 3em;
  padding-bottom: 3em;
  display: flex;
  column-gap: 3em;
}

@media (min-width: 768px) {
  .wrpbeltxt{
    margin-left: 17em;
  }
}
.wrpbolhead{
  color: #ffffff;
  font-size: 2em;
  font-weight: 900;
}

  .wrpbeltxt{
    color: #ffffff;
    padding-top: 5px;
    font-weight: 900;
    font-size: 1.2em;
  }

.wrapbelbtn button{
  margin-top: 1em;
  margin-left: 3em;
  color: #ffffff;
  height: 60px;
  width: 250px;
  border-color: white;
  border-radius: 1em;
  background: linear-gradient(to right,#ea5a32,#00a958);
}

.wrapbelbtn :hover{
  background: linear-gradient(to left,#ea5a32,#00a958);
  color: #1c5fa8;
  cursor: pointer;
}

.wrapbelbtn p{
  font-size: small;
  font-weight: 900;
}

.rmoresunpitem :hover{
  background: linear-gradient(to right,#ea5a32,#00a958);
  color: white;
}


/* Service Section */
.servicesectionhome{
  background: linear-gradient(to right,#ea5a32,#00a958);
}

/* .swiperslide-content{
  height: 610px;
} */

/* .rwhysunpimg{
  background-image: url(../Images\ Homepage/wh-img-1.jpg);
  background-repeat: no-repeat;
  background-size: cover;
} */

.emailsubscribe{
  background: linear-gradient(-45deg, #ddd6d6, #444461); 
}

.lwhysunpitem{
  background: linear-gradient(to right, rgb(37 99 235) 50%, white 50%);
  background-size: 200% 100%;
  background-position: right bottom;
  transition: all .6s ease-out;
}

.lwhysunpitem:hover {
  background-position: left bottom;
  color: white; 
}

.reviewcontdiv {
  padding: 0 10px; /* Add padding to prevent content from touching screen edges */
}

.swiper-slide {
  transition: transform 0.3s ease, filter 0.3s ease;
}

.swiper-slide-active {
  transform: scale(1.1); /* Enlarge the active slide */
  z-index: 2; /* Ensure the active slide is above the others */
}

.swiper-slide-next, .swiper-slide-prev {
  filter: blur(2px); /* Apply blur effect to adjacent slides */
  transform: scale(0.9); /* Optionally scale down the adjacent slides */
  z-index: 1; /* Ensure adjacent slides are below the active slide */
}

/* Adjust the blur effect for different screen sizes */
@media (max-width: 640px) {
  .swiper-slide-next, .swiper-slide-prev {
    filter: blur(2px);
  }
}

@media (min-width: 641px) and (max-width: 768px) {
  .swiper-slide-next, .swiper-slide-prev {
    filter: blur(3px);
  }
}

@media (min-width: 769px) {
  .swiper-slide-next, .swiper-slide-prev {
    filter: blur(4px);
  }
}
